import * as React from 'react';
import { useState } from 'react';
import { useLoaderData, useNavigate, LoaderFunctionArgs } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@mui/material";
import AppBarPage from "../components/AppBarPage";
import { Client, Plan } from '../models/core';
import { AuthData } from '../models/ziphy';
import { API } from '../utils/Api';
import { useLoading } from '../hooks/useLoading';
import CampaignEditor, { CampaignEditorMode } from '../components/CampaignEditor';

export const loader = async ({ params, request }: LoaderFunctionArgs): Promise<[Client, Plan[]]> => {
    let clientId = params.clientId;
    if (!clientId) {
        const match = request.url.match(/clients\/(?<clientId>\w+)\//);
        clientId = match && match.groups && match.groups['clientId'] || '';
        if (!clientId) {
            throw Error('Missing clientId param');
        }
    }

    if(!API.token || API.token == '') {
        const authItem = window.localStorage.getItem('auth');
        const auth = authItem ? (JSON.parse(authItem) as AuthData) : null;
        API.token = auth?.accessToken?.value || '';
    }

    const [client, { items: plans }] = await Promise.all([
        API.getClient(clientId),
        API.getPlans(clientId),
    ]);
    return [client, plans];
}

const CampaignsPage = (): JSX.Element => {
    const [client, plans] = useLoaderData() as [Client, Plan[]];
    //const navigate = useNavigate();
    const loading = useLoading();
    const [openCE, setOpenCE] = useState<boolean>(false);

    React.useEffect(() => {loading.hideLoading();}, [])

    const onNewCampaign = React.useCallback(async () => {
        setOpenCE(true);
    }, []);

    const onCloseCampaignEditor = React.useCallback(async () => {
        setOpenCE(false);
    }, []);


    return (
        <AppBarPage title='Campaigns' actions={<>
            <IconButton color='inherit' onClick={onNewCampaign}><AddIcon /></IconButton>
        </>}>
        {"ERROR: Something Went Wrong, We Failed To Query For Campaigns. Tell the Developers This Is Broken"}
        {
            openCE ? <CampaignEditor open client={client} plans={plans} editorMode={CampaignEditorMode.Dialog}
                        onClose={onCloseCampaignEditor}
            /> : <></>
        }
        </AppBarPage>
    );
}

export default CampaignsPage;