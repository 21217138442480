import * as React from 'react';
import { Fab, FabProps } from '@mui/material';

const StickyFab = ({ children, ...props }: FabProps): JSX.Element => (
    <Fab {...props} color="primary" aria-label="add" sx={{
        position: 'sticky',
        bottom: '40px',
        left:'70vw',
    }}>
        {children}
    </Fab>
)

export default StickyFab;