import * as React from 'react';
import { LoaderFunctionArgs, Navigate, Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import AppBarPage from '../components/AppBarPage';
import { API } from '../utils/Api';
import { Patient, Client, Visit, Plan, VisitStatus } from '../models/core';
import ListItemText from '@mui/material/ListItemText';
import { IconButton, Fab, List, ListItem, ListItemIcon } from '@mui/material';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import { RoutesListItem } from '../components/RoutesList';
import useDialog from '../hooks/useDialog';
import VisitEditor from '../components/VisitEditor';
import { DateTime } from 'luxon';
import PatientEditor from '../components/PatientEditor';
import { CalendarMonth } from '@mui/icons-material';
import { analytics } from '../utils/analytics/zipAnalytics';
import PersonIcon from '@mui/icons-material/Person';
import { AuthData } from '../models/ziphy';
import { useLoading } from '../hooks/useLoading';

type LoaderData = [Client, Patient, Visit[], Plan[]];

export const loader = async ({ params, request }: LoaderFunctionArgs):
    Promise<LoaderData> => {
    let clientId = params.clientId;
    if (!clientId) {
        const match = request.url.match(/clients\/(?<clientId>\w+)\//);
        clientId = match && match.groups && match.groups['clientId'] || '';
    }
    if (!clientId) {
        throw Error('Missing clientId');
    }
    if (!params.patientId) {
        throw Error('Missing patientId');
    }

    if(!API.token || API.token == '') {
        const authItem = window.localStorage.getItem('auth');
        const auth = authItem ? (JSON.parse(authItem) as AuthData) : null;
        API.token = auth?.accessToken?.value || '';
    }
    
    //const searchParams = new URL(request.url).searchParams;
    const [client, patient, visits, plans] = await Promise.all([
        await API.getClient(clientId),
        await API.getPatient(clientId, params.patientId),
        await API.getVisits(clientId, params.patientId),// Array.from(searchParams.entries())),
        await API.getPlans(clientId),
    ]);
    return [client, patient, visits.items, plans.items];
}

const VisitsPage = (): JSX.Element => {
    
    const loading = useLoading();
    React.useEffect(() => { loading.hideLoading(); }, []);

    const [client, patient, visits, plans] = useLoaderData() as LoaderData;
    //const [searchParams, setSearchParams] = useSearchParamsDict<'status'>();
    const navigate = useNavigate();

    const {open: veOpen, show: showVE, hide: hideVE} = useDialog();
    const [visitToEdit, setVisitToEdit] = React.useState<Visit|null>(null);
    const { open: peOpen, show: showPE, hide: hidePE } = useDialog();

    const onAddVisitClick = React.useCallback(() => {
        setVisitToEdit(null);
        showVE();
    }, [showVE]);

    const onEditPE = React.useCallback(() => {
        showPE();
    }, [showPE]);

    const onEditClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        onEditPE && onEditPE();
        event.stopPropagation();
        event.preventDefault();
    }, [onEditPE]);

    const onPatientSaved = React.useCallback(() => {
        navigate('.');
        hidePE();
    }, [hidePE, navigate]);

    const onEditVisitClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        let e: HTMLElement|null = event.currentTarget;
        while (e && !e.attributes.getNamedItem('x-id')) {
            e = e.parentElement;
        }
        const idAttr = e && e.attributes.getNamedItem('x-id');
        if (idAttr) {
            const visitTE = visits.find(v => v._id === idAttr.value)||null;
            setVisitToEdit(visitTE);
            analytics.breadcrumb("visit_select", { patientId:visitTE?.patientId || '', visitId: visitTE?._id || ''});
            showVE();
        }
        event.stopPropagation();
        event.preventDefault();
    }, [showVE, visits]);

    const onVisitSaved = React.useCallback(() => {
        navigate('.');
        hideVE();
        setVisitToEdit(null);
    }, [hideVE, navigate, setVisitToEdit]);
    
    /*const onStatusChange = React.useCallback((event: SelectChangeEvent<string>) => {
        setSearchParams({ status: event.target.value == 'all' ? '' : event.target.value });
    }, [setSearchParams]);*/

    const cmpVisits = (a: Visit, b: Visit) => {
        const st2n: {[k: string]: number} = {
            [VisitStatus.NEW]: 0,
            [VisitStatus.INACTIVE]: 1,
            [VisitStatus.IN_PROGRESS]: 2,
            [VisitStatus.REFUSED]: 3,
            [VisitStatus.UTR]: 4,
            [VisitStatus.SCHEDULED]: 5,
            [VisitStatus.COMPLETED]: 6,
        };
        if (a.status == VisitStatus.COMPLETED && b.status == VisitStatus.COMPLETED) {
            return DateTime.fromISO(a.scheduled1 || a.scheduled2 || a.scheduled3 || a.scheduled4 || a.scheduledDate || ''
            ).diff(
                DateTime.fromISO(b.scheduled1 || b.scheduled2 || a.scheduled3 || a.scheduled4 || b.scheduledDate || ''),
                'seconds'
            ).seconds;
        }
        if (a.status == VisitStatus.SCHEDULED && b.status == VisitStatus.SCHEDULED) {
            return DateTime.fromISO(
                a.scheduled1 || a.scheduled2 || a.scheduled3 || a.scheduled4 || a.scheduledDate || ''
            ).diff(
                DateTime.fromISO(b.scheduled1 || b.scheduled2 || a.scheduled3 || a.scheduled4 || b.scheduledDate || ''),
                'seconds'
            ).seconds;
        }
        return st2n[a.status] - st2n[b.status];
    }

    function extractVisualDate(timestamp: string | null | undefined): string {
        if (timestamp) {
            const splitComps = timestamp?.split('T');
            if(splitComps?.length > 0) {
                return splitComps[0] || 'Unknown';
            }
            return timestamp;
        }
        return 'Unknown';
    }

    function numberOfcheduleAttempts(v: Visit): string {
        let count = 0;
        if((v.scheduled1 || '').length > 0) { count += 1; }
        if((v.scheduled2 || '').length > 0) { count += 1; }
        if((v.scheduled3 || '').length > 0) { count += 1; }
        if((v.scheduled4 || '').length > 0) { count += 1; }
        return count.toString();
    }

    return !client.layout.options.visitsSheet ? <Navigate to='all/measures' /> : (
        <AppBarPage title={patient && `${patient.firstName} ${patient.lastName}`} actions={
            <IconButton color='inherit' onClick={onEditClick}><PersonIcon /></IconButton>}>
            {
                /*<Box sx={{ ml: 4, mr: 3, mt: 2, display: 'flex' }}>
                    <Box flexGrow={1}></Box>
                    <Select value={searchParams.status || 'all'} variant='standard' onChange={onStatusChange}>
                        <MenuItem value='all'>All Statuses</MenuItem>
                        <MenuItem value='scheduled'>Scheduled</MenuItem>
                        <MenuItem value='passed'>Passed</MenuItem>
                        <MenuItem value='unfinished'>Un-finished</MenuItem>
                    </Select>
                </Box>*/
            }
            <List>
                {[...visits.sort(cmpVisits).map((v, i) => (
                    <RoutesListItem 
                        key={i} 
                        x-id={v._id} 
                        to='#'
                        onClick={onEditVisitClick} 
                        hideMenu>
                        <ListItemIcon>
                            <MedicalServicesOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            v.status == VisitStatus.SCHEDULED
                                ? `Scheduled at ${new Date(v.scheduled1 || v.scheduled2|| v.scheduled3 || v.scheduled4|| v.scheduledDate||'').toLocaleDateString()}`
                                    : v.status == VisitStatus.COMPLETED
                                ? `Completed at ${new Date(v.scheduledDate||'').toLocaleDateString()}`
                                    : v.status
                            }
                            secondary={
                            <span>
                                Contact Attempts: {v.numContacts || '0'}
                                <br/>
                                Schedule Attempts: {numberOfcheduleAttempts(v)}
                                <br/>
                                Last Contact Date: {extractVisualDate(v.lastContactDate)}
                                <br/>
                                Last Updated On: {extractVisualDate(v.updated)}
                            </span>
                            }
                        />
                    </RoutesListItem>
                )),
                ...(visits.length == 0 ? [
                    <ListItem key='no-items-msg' sx={{
                        height: '102px',
                        display: 'flex',
                        justifyContent: 'center',
                        background: '#1976d210',
                        color: '#1976d2'
                    }}>
                        There is no visits for this patient
                    </ListItem>
                ] : [])
                
                ]}
            </List>
            <Fab color='primary' title='Schedule' onClick={onAddVisitClick} sx={{
                position: 'sticky',
                bottom: '40px',
                left:'70vw',
            }}>
                <CalendarMonth />
            </Fab>
            <VisitEditor 
                open={veOpen} onClose={()=>{hideVE(); setVisitToEdit(null);}} onSave={onVisitSaved}
                visit={visitToEdit} clientId={client._id} patientId={patient._id} refId={'Visit'+(1+visits.length)}
                />
            {peOpen ? <PatientEditor
                open={true} onClose={hidePE} onSave={onPatientSaved}
                patient={patient} client={client} plans={plans} />
                : <></>}
            <Outlet />
        </AppBarPage >
    );
}

export default VisitsPage;
