import React, { useState } from 'react';
import { Box, Button, List,Pagination, Typography, IconButton, Tooltip } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';


// PaginatedList Component Props
export interface PaginatedListProps<ITEM_TYPE> {
  items: ITEM_TYPE[];
  page: number;
  setPage: (page: number) => void;
  itemsPerPage: number;
  renderItem: (item: ITEM_TYPE) => React.ReactNode;
}

// PaginatedList Component
export const PaginatedList = <ITEM_TYPE,>({
  items,
  page,
  setPage,
  itemsPerPage,
  renderItem,
}: PaginatedListProps<ITEM_TYPE>): React.ReactElement => {
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) =>
    setPage(value);

  return (
    <>
      <List sx={{
                minHeight: '500px',
                maxHeight: '500px',
                overflowY: 'auto', 
            }}>
        {items
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .map((item) => renderItem(item))}
      </List>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
      />
    </>
  );
};

// ShuttleList Component Props
export interface ShuttleListProps<ITEM_TYPE> {
  sourceItems: ITEM_TYPE[];
  setSourceItems: React.Dispatch<React.SetStateAction<ITEM_TYPE[]>>;
  targetItems: ITEM_TYPE[];
  setTargetItems: React.Dispatch<React.SetStateAction<ITEM_TYPE[]>>;
  itemsPerPage?: number;
  moveAll?:boolean;
  sourceTitle?:string;
  targetTitle?:string;
  renderListItem: (
    item: ITEM_TYPE,
    sourceItems: ITEM_TYPE[],
    setSourceItems: React.Dispatch<React.SetStateAction<ITEM_TYPE[]>>,
    targetItems: ITEM_TYPE[],
    setTargetItems: React.Dispatch<React.SetStateAction<ITEM_TYPE[]>>
  ) => React.ReactNode;
}

// ShuttleList Component
const ShuttleList = <ITEM_TYPE,> ({
  sourceItems,
  setSourceItems,
  targetItems,
  setTargetItems,
  itemsPerPage = 5,
  renderListItem,
  sourceTitle = 'Source',
  targetTitle = 'Target',
  moveAll = true
}: ShuttleListProps<ITEM_TYPE>): React.ReactElement =>  {
  const [sourcePage, setSourcePage] = useState(1);
  const [targetPage, setTargetPage] = useState(1);

  const moveItems = (
    from: ITEM_TYPE[],
    setFrom: React.Dispatch<React.SetStateAction<ITEM_TYPE[]>>,
    to: ITEM_TYPE[],
    setTo: React.Dispatch<React.SetStateAction<ITEM_TYPE[]>>,
    items: ITEM_TYPE[]
  ) => {
    setFrom(from.filter((item) => !items.includes(item)));
    setTo([...to, ...items]);
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box flex={1}>
        <Typography variant="h6">{sourceTitle}</Typography>
        <PaginatedList
          items={sourceItems}
          page={sourcePage}
          setPage={setSourcePage}
          itemsPerPage={itemsPerPage}
          renderItem={(item) =>
            renderListItem(item, sourceItems, setSourceItems, targetItems, setTargetItems)
          }
        />
      </Box>
      { moveAll ? 
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mx={2}>
          <Tooltip title={`Move All Items from ${sourceTitle} to ${targetTitle}`}>
            <IconButton onClick={() => moveItems(sourceItems, setSourceItems, targetItems, setTargetItems, sourceItems)}>
              <KeyboardDoubleArrowRightIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title={`Move All Items from ${targetTitle} to ${sourceTitle}`}>
            <IconButton
                onClick={() => moveItems(targetItems, setTargetItems, sourceItems, setSourceItems, targetItems)}
                sx={{ mt: 2 }}
              >
                <KeyboardDoubleArrowLeftIcon/>
            </IconButton>
          </Tooltip>
        </Box>
      : <></>}

      <Box flex={1}>
        <Typography variant="h6">{targetTitle}</Typography>
        <PaginatedList
          items={targetItems}
          page={targetPage}
          setPage={setTargetPage}
          itemsPerPage={itemsPerPage}
          renderItem={(item) =>
            renderListItem(item, sourceItems, setSourceItems, targetItems, setTargetItems)
          }
        />
      </Box>
    </Box>
  );
};

export default ShuttleList;