import * as React from 'react';
import { useCallback, useState } from 'react';
import { DocumentInfo } from '../models/core';
import { Typography, Grid, Box, IconButton } from '@mui/material';

import { GridFormItem } from './GridForm';
import { useAuth } from '../hooks/useAuth';
import useSnackbar from '../hooks/useSnackbar';
import AlertSnack from './ErrorSnack'
import { authDocumentInfo } from '../utils/ResourceUtils'

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import TextTable from './TextTable';

export enum PatientMeasureEditorSection {
    Demographics = 'Demographics',
    Measures = 'Measures'
}

export interface PatientMeasureEditorData {
    _id:string;
    visitId?:string;
    measure:string;
    value:string;
    info:DocumentInfo;
}

export interface PatientMeasureEditorState {
    updated:boolean;
    measures:PatientMeasureEditorData[];
}

interface PatientMeasureEditorProps {
    sections?:PatientMeasureEditorSection[];
    state?:PatientMeasureEditorState;
    readonly?:boolean;
    onStateUpdate?:(state:PatientMeasureEditorState) => void;
}

const PatientMeasureEditor = ({ sections, readonly=false, state, onStateUpdate }: PatientMeasureEditorProps): JSX.Element => {
    

    const { showError: snackError, ...snackProps } = useSnackbar();
    const [measureList, setMeasureList] = useState<PatientMeasureEditorData[]>(state?.measures || []);
    const [changesMade, setChangesMade] = useState<boolean>(false);

    const measureEntryList = React.useMemo(() => [
        {code:'ACP', name:'Advance Care Planning (ACP)' },
        {code:'AWV', name:'Annual Wellness Visit (AWV)' },
        {code:'COA', name:'Care for Older Adults (COA)' },
        {code:'COA-MDR', name:'Care for Older Adults (COA) - Medication Review' },
        {code:'COA-FSA', name:'Care for Older Adults (COA) - Functional Status Assessment' },
        {code:'COA-PA', name:'Care for Older Adults (COA) - Pain Assessment' },
        {code:'CBP', name:'Controlling High Blood Pressure (CBP)' },
        {code:'BPD', name:'Blood Pressure Control for Patients With Diabetes (BPD)' },
        {code:'EED', name:'Eye Exam for Patient with Diabetes (EED)' },
        {code:'HBD', name:'Hemoglobin A1c Control for Patients With Diabetes (HBD)' },
        {code:'TRC', name:'Transitions of Care: Medication Reconciliation Post-Discharge (TRC)' },
        {code:'FRM', name:'Fall Risk Management (FRM)' },
        {code:'DSF-E', name:'Depression Screening and Follow-Up for Adolescents and Adults (DSF-E)' },
        {code:'DMS-E', name:'Utilization of the PHQ-9 to Monitor Depression Symptoms for Adolescents and Adults (DMS-E)' },
        {code:'ASF-E', name:'Unhealthy Alcohol Use Screening and Follow-Up (ASF-E)' },
        {code:'MSC', name:'Medical Assistance With Smoking and Tobacco Use Cessation (MSC)' },
        {code:'SPC', name:'Statin Therapy for Patients with Cardiovascular Disease (SPC)' },
        {code:'SPC|SPD', name:'Statin Therapy for Patients With Cardiovascular Disease and Diabetes (SPC, SPD)' },
        {code:'SPD', name:'Statin Therapy for Patients with Diabetes (SPD)' },
        {code:'SUPD', name:'Statin Use in Persons with Diabetes (SUPD)' },
        {code:'KED', name:'Kidney Health Evaluation for Patients With Diabetes (KED)' },
        {code:'FMC', name:'Follow-Up After Emergency Department Visit for People With Multiple High-Risk Chronic Conditions (FMC)' },
        {code:'MUI', name:'Management of Urinary Incontinence in Older Adults (MUI)' },
        {code:'PAO', name:'Physical Activity in Older Adults (PAO)' },
        {code:'MSC', name:'Medical Assistance With Smoking and Tobacco Use Cessation (MSC)' },
        {code:'BCS-E', name:'Breast Cancer Screening (BCS-E)' },
        {code:'COL-E', name:'Colorectal Cancer Screening (COL-E)' },
        {code:'AIS-E', name:'Adult Immunization Status (AIS-E)' },
        {code:'SNS-E', name:'Social Need Screening and Intervention (SNS-E)' },
    ], []);

    const complianceList = React.useMemo(() => [
        {code:'', name:'NA' },
        {code:'C', name:'Compliant' },
        {code:'NC', name:'Non-Compliant' }
    ], []);

    const auth = useAuth();

    const checkRenderSection = useCallback((section:PatientMeasureEditorSection, array:PatientMeasureEditorSection[]|undefined, def = true) => {
        return (!array || array?.length == 0) ? def : array?.findIndex((s) => section == s) >= 0;
    },[]);

    React.useEffect(() => {
        if(!changesMade || readonly) {
            return;
        }
        onStateUpdate && onStateUpdate(
            {
                updated:true,
                measures:measureList
            }
        );
    }, [measureList, onStateUpdate, changesMade, readonly]);

    //  Demographics
    const demographicsComponents = React.useMemo<JSX.Element>(() => {
        return checkRenderSection(PatientMeasureEditorSection.Demographics, sections, true) ? (<GridFormItem xs={12}>
            <Grid container spacing={2}>
                <GridFormItem xs={12}>
                    <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Measures</Typography>
                </GridFormItem>   
            </Grid>
            <GridFormItem xs={12}><Box sx={{ height: '0.5em' }} /></GridFormItem>
            </GridFormItem>) : <></>;
    },[sections, checkRenderSection]);

    const measureListKeys = React.useMemo(() => {
        const sortedList = measureEntryList.sort((a, b) => a.name.localeCompare(b.name));
        return sortedList?.map((it) => { return { key:it.name, value:it.code } });
    },[measureEntryList]);

    const complianceListKeys = React.useMemo(() => {
        return complianceList?.map((it) => { return { key:it.name, value:it.code } });
    },[complianceList]);

    const copyTableContent = React.useCallback((contentName:string, content: PatientMeasureEditorData[]) => {
        let copyData:string = contentName;
        content?.forEach((it) => {
            const measureData = measureListKeys?.find((fi) => {return fi.value === it.measure;});
            if(measureData) {
                copyData = copyData + `\n${measureData.key}`;
            }
        });
        navigator.clipboard.writeText(copyData);
    },[measureListKeys])

    const checkCanAddRow = React.useCallback((nr: PatientMeasureEditorData, cbk:()=>void) => {
        if(!nr.measure || nr.measure == '') {
            snackError(new Error('Measure must be set'));
            return [{ key:'measure', label:'Measure must be set' }];
        }
        cbk();
        return undefined;
    }, [snackError]);


    const measureComponents = React.useMemo<JSX.Element>(() => {
        const handleAddRow = (newRow: PatientMeasureEditorData) => {
            setMeasureList([...measureList, {...newRow, 
                _id:Date.now().toString(), info:authDocumentInfo(auth?.user, `create: ${newRow.measure}`)}]);
                setChangesMade(true);
        };

        const handleDeleteRow = (newRow: PatientMeasureEditorData) => {
            setMeasureList([...measureList.filter(el => el._id != newRow._id)]);
            setChangesMade(true);
        };

        const handleEditRow = (newRow: PatientMeasureEditorData) => {
            const index = measureList.findIndex(el => el._id == newRow._id && (el.measure !== newRow.measure || newRow.value !== el.value));
            if(index !== -1) {
                setMeasureList(prevArray => {
                    const newArray = [...prevArray];
                    newArray[index] = {...newRow, 
                        info:authDocumentInfo(auth?.user, `edit: ${measureList[index].measure} to ${newRow.measure}`)}
                    return newArray;
                });
                setChangesMade(true);
            }
        };

        return checkRenderSection(PatientMeasureEditorSection.Measures, sections, true) ? (
            <Grid container spacing={2}>
            <GridFormItem xs={11}>
                <Typography variant='h6' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Measures</Typography>
            </GridFormItem>
            <GridFormItem xs={1}>
                <IconButton onClick={() => copyTableContent('Measures', measureList)}><ContentCopyIcon/></IconButton>
            </GridFormItem>
            <GridFormItem xs={12}>
                <TextTable
                    readonly={readonly}
                    addRowHeader={"Select Measure:"}
                    rows={measureList}
                    columns={[
                        { key: 'measure', label: 'Measure', entries:measureListKeys, type:'enum' },
                    ]}
                    onAddRow={handleAddRow}
                    canAddRow={checkCanAddRow}
                    onDeleteRow={handleDeleteRow}
                    onUpdateRow={handleEditRow}
                />
            </GridFormItem>
            <GridFormItem xs={12}><Box sx={{ height: '0.5em' }} /></GridFormItem>
            </Grid>
            ) : <></>;
    },[measureList, setMeasureList, checkRenderSection, copyTableContent, 
        checkCanAddRow, sections, measureListKeys, complianceListKeys, auth?.user, readonly]);

    return <Grid container spacing={2}>
        <GridFormItem xs={12}>
                { demographicsComponents } { measureComponents }
        </GridFormItem>
        <AlertSnack {...snackProps}/>
    </Grid>
}

export default PatientMeasureEditor;