import * as React from 'react';
import { useCallback, useState } from 'react';
import { Client, Plan, PatientStatus } from '../models/core';
import { TextField, Typography, Grid,
    Stack,
    ListItem,
    ListItemText} from '@mui/material';
import { GridFormItem } from './GridForm';
import { PatientStatuses, ScheduledFailedReasons } from '../utils/enums';
import EntityEditor, { EntityEditorProps } from './EntityEditor';
import { EnumField } from './fields';
import FiltersPanel, { FilterField } from '../components/FiltersPanel';

import useSearchParamsDict from '../hooks/useSearchParamsDict';
import ShuttleList from './ShuttleList'

export enum CampaignEditorMode {
    Dialog = 'Dialog',
    Grid = 'Grid',
    Element = 'Element'
}

interface CampaignEditorProps extends Omit<EntityEditorProps,'title'|'doSave'> {
    client: Client;
    plans: Plan[];
    editorMode?:CampaignEditorMode
}

/* Filter Stuff Start*/
type CampaignFilterFields = 'search' | 'rgx.firstName' | 'rgx.lastName' | 'planId' 
    | 'key.scheduledReason' | 'status' | 'key.assignments';


type ListItemType = {
    id:string;
    label:string;
    name: string;
    status:string;
    dob:string;
    phone1:string;
    phone2:string;
    activeContact:string;
};



const CampaignEditor = ({ client, plans, editorMode, readOnly, ...props }: CampaignEditorProps): JSX.Element => {


    const [sourceItems, setSourceItems] = useState<ListItemType[]>([
        { id: '1', label:'', name: 'Item 1', status:'Active', dob:'1/1/2020', phone1:'0000000000', phone2:'1111111111', activeContact:'0000000000' },
        { id: '2', label:'', name: 'Item 2', status:'Active', dob:'1/1/2020', phone1:'0000000000', phone2:'1111111111', activeContact:'0000000000' },
        { id: '3', label:'', name: 'Item 3', status:'Active', dob:'1/1/2020', phone1:'0000000000', phone2:'1111111111', activeContact:'0000000000' },
        { id: '4', label:'', name: 'Item 4', status:'Active', dob:'1/1/2020', phone1:'0000000000', phone2:'1111111111', activeContact:'0000000000' },
        { id: '5', label:'', name: 'Item 5', status:'Active', dob:'1/1/2020', phone1:'0000000000', phone2:'1111111111', activeContact:'0000000000' },
        { id: '6', label:'', name: 'Item 6', status:'Active', dob:'1/1/2020', phone1:'0000000000', phone2:'1111111111', activeContact:'0000000000' },
        { id: '7', label:'', name: 'Item 7', status:'Active', dob:'1/1/2020', phone1:'0000000000', phone2:'1111111111', activeContact:'0000000000' },
        { id: '8', label:'', name: 'Item 8', status:'Active', dob:'1/1/2020', phone1:'0000000000', phone2:'1111111111', activeContact:'0000000000' },
      ]);
      const [targetItems, setTargetItems] = useState<ListItemType[]>([]);
    
      const renderListItem = (
        item: ListItemType,
        sourceItems: ListItemType[],
        setSourceItems: React.Dispatch<React.SetStateAction<ListItemType[]>>,
        targetItems: ListItemType[],
        setTargetItems: React.Dispatch<React.SetStateAction<ListItemType[]>>
      ) => (
        <ListItem
          key={item.id}
          onClick={() => {
            if (sourceItems.includes(item)) {
              setSourceItems(sourceItems.filter((i) => i !== item));
              setTargetItems([...targetItems, item]);
            } else {
              setTargetItems(targetItems.filter((i) => i !== item));
              setSourceItems([...sourceItems, item]);
            }
          }}
        >
          <ListItemText primary={`${item.name} (${item.dob})`} 
            secondary={
                <Stack>
                    <Typography>{`Status: ${item.status}`}</Typography>
                </Stack>
            }
          />
        </ListItem>
      );



    const [campaignText, setCampaignText] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [campaignType, setCampaignType] = useState("SMS");
    const [campaignID, setCampaignID] = useState("");

    const [searchParams, setSearchParams] = useSearchParamsDict<CampaignFilterFields>();

    const filterValue = React.useMemo<{ [k in CampaignFilterFields]: string | string[] }>(() => ({
        search: searchParams.search || '',
            'rgx.firstName': searchParams['rgx.firstName'] || '',
            'rgx.lastName': searchParams['rgx.lastName'] || '',
            planId: searchParams.planId?.split('|') || '',
            'key.scheduledReason': searchParams['key.scheduledReason']?.split('|') || ['all'],
            'status' : !searchParams['status'] ? [PatientStatus.ACTIVE] : (searchParams['status']?.split('|') || ['all']),
        'key.assignments': searchParams['key.assignments']?.split('|') || ['all']
    }), [searchParams])


    const onFiltersChange = React.useCallback((value: { [k: string]: string | string[] }) => {
        function maybeJoin(x: string | string[]): string {
            return x == 'all' ? '' : (x instanceof Array) ? (x.includes('all') ? 'all' : x.join('|')) : x;
        }
        //setFiltersSyncing(true);
        setSearchParams({
            search: maybeJoin(value.search) || '',
            'rgx.firstName': maybeJoin(value['rgx.firstName']) || '',
            'rgx.lastName': maybeJoin(value['rgx.lastName']) || '',
            planId: plans.filter(p => (value.planId as string[]).includes(p.name)).map(p => p._id).join('|'),
            'key.scheduledReason': maybeJoin(value['key.scheduledReason']) || '',
            'status': maybeJoin(value['status']) || '',
            'key.assignments': maybeJoin(value['key.assignments']) || '',
        });
    }, [plans, setSearchParams]);

    const filterFields = React.useMemo<FilterField[]>(() => [
        { name: 'search', type: 'search', placeholder: 'Insurance/Medicare/Medicaid ID', width:12 },
        { name: 'rgx.firstName', type: 'string', placeholder: 'First Name', width:12 },
        { name: 'rgx.lastName', type: 'string', placeholder: 'Last Name', width:12 },
        { name: 'planId', type: 'enum', items: plans.map(p => p.name), emptyLabel: 'All Plans', width:12},
        { name: 'key.scheduledReason', type: 'enum', items: ScheduledFailedReasons, emptyLabel: 'Any Schedule Reason', width:12},
        { name: 'status', type: 'enum', items: PatientStatuses, emptyLabel: "Any Status", width:12},
        { name: 'key.assignments', type: 'enum', items: client.repNames ? ['None', 'Assigned',...client.repNames] : [], emptyLabel:'Any Assignment', 
            disabled:!(client.repNames?.length != 0), width:12 },
    ], [client.repNames, plans]);


    const lastEditFooter = React.useMemo(() => {
        return { text:'ERROR', tooltip:'ERROR' };
    },[]);

    const onTriggerCampaign = useCallback(async () => {
        return true;
    },[]);

    const campaignFilters = React.useMemo<JSX.Element>(() => {
        return <FiltersPanel fields={filterFields} defaultValue={filterValue} isBusy={false} 
            onChange={onFiltersChange} collapsable={false} gridPadding='8px 2px 0 2px'/>
    }, [filterValue, onFiltersChange, filterFields]);


    if(editorMode == undefined || CampaignEditorMode.Dialog == editorMode) {
        return (<EntityEditor {...props} readOnly={readOnly} title={'Create Campaign'} 
                footer={lastEditFooter} doSave={onTriggerCampaign} submitText={'Run Campaign'} backdropClose={false}
                dialogPaperProps={{sx:{maxWidth:'1900', minWidth:'1200px', height:'100%'}}}>

            <GridFormItem xs={8}>
                <TextField name='campaignName' label='Name' value={campaignName}
                    onChange={ (ev) => setCampaignName(ev.target.value) } variant='standard' required fullWidth />
            </GridFormItem>
            <GridFormItem xs={1}/>
            <GridFormItem xs={3}>
                <TextField name='campaignID' label='ID' value={campaignID}
                    onChange={ (ev) => setCampaignID(ev.target.value) } variant='standard' fullWidth />
            </GridFormItem>

            <GridFormItem xs={8}>
                <TextField name='campaignMessage' label='Message' value={campaignText}
                    onChange={ (ev) => setCampaignText(ev.target.value) } rows={2} variant='standard'
                    required multiline fullWidth inputProps={{ maxLength: 140 }}/>
                <Typography component='div' sx={{ display: 'flex', justifyContent: 'right' }}>0 of 0</Typography>
            </GridFormItem>
            <GridFormItem xs={2}/>
            <GridFormItem xs={2}>
                <EnumField name='campaignType' onChange={(ev)=>{setCampaignType(ev)}} label='Type' 
                    value={campaignType} options={['SMS']} required readOnly={true} />
            </GridFormItem>

            <GridFormItem xs={3}>
                { campaignFilters }
            </GridFormItem>
            <GridFormItem xs={9}>
            
                <ShuttleList<ListItemType>
                    sourceItems={sourceItems}
                    setSourceItems={setSourceItems}
                    targetItems={targetItems}
                    setTargetItems={setTargetItems}
                    itemsPerPage={10}
                    renderListItem={renderListItem}
                />
            </GridFormItem>
        </EntityEditor >);
    } else if(CampaignEditorMode.Grid == editorMode) {
        return <Grid container spacing={2}>
            { campaignFilters }
        </Grid>
    }
    return (<>{ campaignFilters }</>);

}

export default CampaignEditor