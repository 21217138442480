import * as React from 'react';
import { useCallback, useState } from 'react';
import GridForm, { GridFormItem } from './GridForm';
import { Autocomplete, Box, Checkbox, FormControlLabel, TextField, Typography, Tabs, Tab } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Client, CustomFields as CustomFieldsType, Patient, Visit, VisitStatus, DocumentInfo, ParentField, SchedulableSlot } from '../models/core';
import { DateTime } from 'luxon';
import { VisitStatuses, MemberInfoUpdates, RefusalReasons, Languages, ScheduledFailedReasons, UnknownLanguage } from '../utils/enums';
import { API } from '../utils/Api';
import { stringToDateTime, authDocumentInfo } from '../utils/ResourceUtils'
import CustomFields, { CustomValue } from './CustomFields';
import useAptSearch, { AppointmentExt } from '../hooks/useAptSearch';
import { API as ZiphyAPI } from '../utils/ZiphyAPI';
import EntityEditor, { EntityEditorProps } from './EntityEditor';
import PatientEditor, { PatientSection, PatientEditorMode } from './PatientEditor';
import { DateTimeField, EnumField } from './fields';
import { analytics } from '../utils/analytics/zipAnalytics';
import SchedulableTimeList from './SchedulableTimeList';
import { useAuth } from '../hooks/useAuth';
import { AutocompleteField as CFAutocompleteField, DateField as CFDateField, EnumField as CFEnumField } from './fields';
import PatientMeasureEditor, { PatientMeasureEditorData, PatientMeasureEditorState } from './PatientMeasureEditor';
import ConditionInfoEditor, { PatientConditionEditorState, PatientConditionPanelData } from './ConditionInfoEditor';


interface DateFieldProps {
    name: string;
    label: string;
    value: DateTime | null;
    readOnly?: boolean;
    disabled?: boolean;
    onChange?: (name: string, value: DateTime | null) => void;
}

const DateField = ({ name, label, value, readOnly, disabled, onChange }: DateFieldProps): JSX.Element => {
    const onPickerChange = useCallback((value: string | null) => {
        onChange && onChange(name, value && DateTime.fromISO(value) || null);
    }, [name, onChange]);
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Box sx={{ '.MuiFormControl-root': { width: '100%' } }}>
                <DatePicker
                    label={label}
                    inputFormat='yyyy-MM-dd'
                    value={value}
                    renderInput={(params) => <TextField name={name} required {...params} variant='standard' />}
                    onChange={onPickerChange}
                    readOnly={readOnly}
                    disabled={disabled} />
            </Box>
        </LocalizationProvider>
    );
}

function formatDates(fields: CustomFieldsType): CustomFieldsType {
    return Object.fromEntries(Object.entries(fields).map(([k, v]) => [
        k,
        v instanceof Date
            ? (DateTime.fromJSDate(v).toISO({
                suppressMilliseconds: true, suppressSeconds: true, includeOffset: false
            }) || '')
            : v
    ]))
}

interface Props extends Omit<EntityEditorProps, 'title' | 'doSave'> {
    clientId: string;
    patientId: string;
    refId: string;
    visit?: Visit | null;
}

function getNewestDate(dates: (DateTime | null)[]) {
    const dateObjects = dates?.map(date => date ? date.toUnixInteger() : 0) || [];
    const idx = dateObjects.indexOf(Math.max(...dateObjects));
    return idx != -1 ? dates[idx] : undefined;
}

function findLastContactDate(fields: CustomFieldsType) {
    const dateFields = ['Call Attempt 1','Call Attempt 2','Call Attempt 3','Call Attempt 4','Call Attempt 5','Call Attempt 6',
            'Date of Text Attempt', 'Date of Email Attempt', 'Date of Mail Attempt'];
    const dateArray:DateTime[] = []
    dateFields.forEach(key => {
        const val = fields[key];
        if(val != undefined) {
            if(val instanceof Date) {
                const dtCheck = DateTime.fromJSDate(val);
                dtCheck != undefined && dtCheck != null && dateArray.push(dtCheck);
            } else if(typeof val === 'string') {
                const dtCheck = DateTime.fromISO(val);
                dtCheck != undefined && dtCheck != null && dateArray.push(dtCheck);
            }
        }
    });
    return getNewestDate(dateArray);
}

function parseStrArray(key: string | undefined, strArr: string[]) {
    const lck = key?.toLocaleLowerCase();
    return strArr.find(item => item.toLocaleLowerCase() === lck) || key;
}

const VisitEditor = ({ visit, clientId, patientId, refId, readOnly, ...props }: Props): JSX.Element => {
    const [client, setClient] = useState<Client | null>(null);
    const [patient, setPatient] = useState<Patient | null>(null);
    const [appointment, setAppointment] = useState<AppointmentExt | null>(null);
    const [status, setStatus] = useState(visit?.status || VisitStatus.NEW + '');
    const [language, setLanguage] = useState(parseStrArray(visit?.language, Languages) 
            || parseStrArray(patient?.language, Languages) || UnknownLanguage);
    const [scheduled1, setScheduled1] = useState( stringToDateTime(visit?.scheduled1) );
    const [scheduledReason1, setScheduledReason1] = useState(visit?.scheduledReason1 || null);
    const [scheduled2, setScheduled2] = useState( stringToDateTime(visit?.scheduled2) );
    const [scheduledReason2, setScheduledReason2] = useState(visit?.scheduledReason2 || null);
    const [scheduled3, setScheduled3] = useState( stringToDateTime(visit?.scheduled3) );
    const [scheduledReason3, setScheduledReason3] = useState(visit?.scheduledReason3 || null);
    const [scheduled4, setScheduled4] = useState( stringToDateTime(visit?.scheduled4) );
    const [scheduledReason4, setScheduledReason4] = useState(visit?.scheduledReason4 || null);
    const [comments, setComments] = useState(visit?.comments || '');
    const [clientNotes, setclientNotes] = useState<string | null | undefined>(visit?.clientNotes || '');
    const [clientDataEntry, setclientDataEntry] = useState<string | null | undefined>(visit?.clientDataEntry || '');
    const [refusalReason, setRefusalReason] = useState<string | null | undefined>(visit?.refusalReason || '');
    const [customFields, setCustomFields] = useState(visit?.customFields || {});
    const [numReschedules, setNumReschedules] = useState(visit?.numReschedules || 0);
    const [phoneDate, setphoneDate] = useState( stringToDateTime(visit?.phoneDate) );
    const [emailDate, setemailDate] = useState( stringToDateTime(visit?.emailDate) );
    const [utrDate, setutrDate] = useState( stringToDateTime(visit?.utrDate) );
    const [phoneOutcome, setphoneOutcome] = useState(visit?.phoneOutcome || null);
    const [emailOutcome, setemailOutcome] = useState(visit?.emailOutcome || null);
    const [appointmentOutcome, setappointmentOutcome] = useState(visit?.appointmentOutcome || null);
    const [notes, setnotes] = useState(visit?.notes || '');
    const [memberInfoUpdate, setmemberInfoUpdate] = useState(visit?.memberInfoUpdate || null);
    const [errors, setErrors] = useState<{ [k in keyof Visit]?: boolean }>({});
    const [sentToPCP, setSentToPCP] = useState<boolean | null | undefined>(visit?.sentToPCP || null);
    
    const [trackChanges, setTrackChanges] = useState({});
    const [displayTabValue, setDisplayTabValue] = useState(0);
    const [schedulableSlots, setSchedulableSlots] = useState<SchedulableSlot|null>(null);
    const [scheduleTime, setScheduleTime] = useState<SchedulableSlot|null>(null);

    const [patientConditionState, setPatientConditionState] = useState<PatientConditionEditorState>();
    const [patientMeasureState, setPatientMeasureState] = useState<PatientMeasureEditorState>();
    
    const auth = useAuth();

    React.useEffect(() => {
        setStatus(visit?.status || VisitStatus.NEW + '');
        setLanguage(parseStrArray(visit?.language, Languages) 
                || parseStrArray(patient?.language, Languages) || UnknownLanguage);
        setScheduled1( stringToDateTime(visit?.scheduled1) );
        setScheduled2( stringToDateTime(visit?.scheduled2) );
        setScheduled3( stringToDateTime(visit?.scheduled3) );
        setScheduled4( stringToDateTime(visit?.scheduled4) );
        setScheduledReason1(visit?.scheduledReason1 || null);
        setScheduledReason2(visit?.scheduledReason2 || null);
        setScheduledReason3(visit?.scheduledReason3 || null);
        setScheduledReason4(visit?.scheduledReason4 || null);
        setComments(visit?.comments || '');
        setclientNotes(visit?.clientNotes || '');
        setclientDataEntry(visit?.clientDataEntry || '');
        setRefusalReason(visit?.refusalReason || null);
        setCustomFields(visit?.customFields || {});
        setNumReschedules(visit?.numReschedules || 0);
        setphoneDate( stringToDateTime(visit?.phoneDate) );
        setemailDate( stringToDateTime(visit?.emailDate) );
        setutrDate( stringToDateTime(visit?.utrDate) );
        setphoneOutcome(visit?.phoneOutcome || null);
        setemailOutcome(visit?.emailOutcome || null);
        setappointmentOutcome(visit?.appointmentOutcome || null);
        setnotes(visit?.notes || '');
        setmemberInfoUpdate(visit?.memberInfoUpdate || null);
        setSentToPCP(visit?.sentToPCP || null);


        async function fetchPatientData() {
            if(patient && patient?.clientId && patient?._id) {
                const trackableList = await API.getPatientTrackables(patient?.clientId, patient?._id);

                const conditionsList:Record<string, PatientConditionPanelData[]> = {};
                const measuresList:PatientMeasureEditorData[] = [];
                trackableList?.items?.forEach((it) => {
                        it?.trackable?.forEach((item) => {
                            if(item.type == 'chronic' || item.type == 'suspect' || item.type == 'diagnosis') {
                                if(!conditionsList[item.type]) {
                                    conditionsList[item.type] = [];
                                }
                                conditionsList[item.type].push({
                                    _id:item.id || (Date.now().toString() + 'a' + conditionsList[item.type].length).toString(),
                                    visitId:it.visitId,
                                    name:item.name || '',
                                    icd:item.value || '',
                                    info:item.info
                                })
                            } else if(item.type == 'measure') {
                                measuresList.push({
                                    _id:item.id || (Date.now().toString() + 'a' + conditionsList[item.type].length).toString(),
                                    visitId:it.visitId,
                                    measure:item.name || '',
                                    value:item.value || '',
                                    info:item.info
                                })
                            }
                        })
                    }
                );

                setPatientConditionState({
                    updated:false,
                    chronic:conditionsList['chronic'] || [], 
                    suspect:conditionsList['suspect'] || [], 
                    diagnosis:conditionsList['diagnosis'] || [], 
                });
                setPatientMeasureState({
                    updated:false,
                    measures:measuresList
                });
            }
        }
        fetchPatientData();

    }, [visit, patient]);

    React.useEffect(() => {
        const fetch = async () => {
            const [client, patient, apt] = await Promise.all([
                API.getClient(visit && visit.clientId || clientId),
                API.getPatient(visit?.clientId || clientId, visit?.patientId || patientId),
                visit?.appointmentId && ZiphyAPI.getAppointment(visit.appointmentId + '') || null
            ]);
            setClient(client || null);
            setPatient(patient || null);
            setAppointment(apt && { ...apt.appointment, patient: apt.expanded.patients.items[0].value },
            );

            const schedulable = visit && await API.getSchedulableSlot(clientId, visit.patientId).catch(console.log);
            setSchedulableSlots(schedulable||null);
        }
        fetch().catch(console.error)
    }, [visit, clientId, patientId]);


    const updateTrackedFields = useCallback((key:string, value:boolean) => {
        setTrackChanges((prevChanges) => ({ ...prevChanges, [key]: value, }));
    }, []);

    const onDateFieldChange = useCallback((name: string, value: DateTime | null) => {
        let changeValue:DateTime|null|undefined;
        switch (name) {
            case 'utrDate': setutrDate(value); break;
            case 'scheduled1': setScheduled1(value); break;
            case 'scheduled2': setScheduled2(value); break;
            case 'scheduled3': setScheduled3(value); break;
            case 'scheduled4': setScheduled4(value); break;
        }
        updateTrackedFields(name, (changeValue != value));
    }, [updateTrackedFields]);

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'numReschedules': !event.target.value.startsWith('-') && setNumReschedules(Number.parseInt(event.target.value)); break;
            case 'notes': setnotes(event.target.value); break;
            case 'comments': setComments(event.target.value); break;
            case 'clientNotes': setclientNotes(event.target.value); break;
            case 'clientDataEntry': setclientDataEntry(event.target.value); break;
            case 'sentToPCP': setSentToPCP(event.target.checked); break;
        }
    }, []);

    const onEnumFieldChange = useCallback((name: string, value: string | null) => {
        let changeValue:string|null|undefined;
        switch (name) {
            case 'language': setLanguage(value || ''); changeValue = visit?.language; break;
            case 'appointmentOutcome': setappointmentOutcome(value || ''); changeValue = visit?.appointmentOutcome; break;
            case 'memberInfoUpdate': setmemberInfoUpdate(value || ''); changeValue = visit?.memberInfoUpdate; break;
            case 'status': setStatus(value || ''); changeValue = visit?.status; break;
            case 'refusalReason': setRefusalReason(value || ''); changeValue = visit?.refusalReason; break;
            case 'scheduledReason1': setScheduledReason1(value || ''); changeValue = visit?.scheduledReason1; break;
            case 'scheduledReason2': setScheduledReason2(value || ''); changeValue = visit?.scheduledReason2; break;
            case 'scheduledReason3': setScheduledReason3(value || ''); changeValue = visit?.scheduledReason3; break;
            case 'scheduledReason4': setScheduledReason4(value || ''); changeValue = visit?.scheduledReason4; break;
        }
        updateTrackedFields(name, (changeValue != value));
    }, [updateTrackedFields, visit?.scheduledReason1, visit?.scheduledReason2, visit?.scheduledReason3, visit?.scheduledReason4,
        visit?.refusalReason, visit?.memberInfoUpdate, visit?.appointmentOutcome, visit?.language, visit?.status]);

    const onCustomFieldsChange = useCallback((value: { [k: string]: string | number | Date | boolean | undefined }) => {
        setCustomFields(Object.fromEntries(Object.entries(value).filter(([, v]) => v).map(([k, v]) => [k, v || ''])));
    }, [setCustomFields]);


    function mapCustomFields(search:string, genCount:number, source:ParentField[]|undefined) {
        const fieldEmail:ParentField[] = source?.filter(f => !f.field && f.label.startsWith(search)) || [];
        return Object.keys([...new Array(genCount)]).flatMap((_, i) => 
                fieldEmail?.map(f => ({ ...f, label: f.label.slice(search.length + 1).replace('%d', (i + 1).toString()) })));
    }

    const [emailAttemptFields, smsAttemptFields, callAttemptFields, mailAttemptFields, 
        otherCustomFields, disabledFields, allFieldKeys] = React.useMemo(() => {

        const eAR = mapCustomFields('Email:', client?.layout.options.numEmailAttempts || 0, client?.layout.visitFields);
        const eSMS = mapCustomFields('SMS:', client?.layout.options.numSMSAttempts || 0, client?.layout.visitFields);
        const eCall = mapCustomFields('Call:', client?.layout.options.numCallAttempts || 0, client?.layout.visitFields);
        const eMail = mapCustomFields('Mail:', client?.layout.options.numMailAttempts || 0, client?.layout.visitFields);

        const fieldOther = client?.layout.visitFields.filter(f => !f.field
            && (!f.label.startsWith('Call:')
                && !f.label.startsWith('SMS:')
                && !f.label.startsWith('Email:')
                && !f.label.startsWith('Mail:'))) || [];

        const disabled = client?.layout.visitFields.filter(f => f.state == 'disabled').flatMap(f => f.field ? [f.field] : []) || [];

        const allFieldKeys = client?.layout.visitFields.filter(f => !f.field)?.map((f) => f?.label)?.concat(
            eAR.map((f) => f?.label), eSMS.map((f) => f?.label), eCall.map((f) => f?.label), eMail.map((f) => f?.label)
        ) || [];

        return [eAR, eSMS, eCall, eMail, fieldOther, disabled, allFieldKeys];
    }, [client?.layout.visitFields, client?.layout.options.numEmailAttempts, client?.layout.options.numMailAttempts,
        client?.layout.options.numSMSAttempts, client?.layout.options.numCallAttempts]);

        
    const customCallRendList = React.useMemo(() => {
        const fieldDataSwipe = callAttemptFields.map((f) => {
            if(f.type == 'autocomplete') {
                //f.items = client?.userAccess?.filter(it => it?.assignable)?.map(it => it.name) || client?.repNames || []
                f.items = client?.repNames || []
            }
            return f;
        });

        const remapValue:{ [k: string]: CustomValue } = customFields || {};
        const remapFields = Object.fromEntries((fieldDataSwipe || {}).map(i => [i.label, i]));
        function callOnChange(label: string, newValue: CustomValue | null) {
            onCustomFieldsChange && onCustomFieldsChange({...remapValue, [label]: newValue || undefined });
        }
        const fields = Array.from({ length: (client?.layout?.options.numCallAttempts || 6) }, (_, i) => i + 1);

        return (callAttemptFields.length > 0 &&
            <GridFormItem container xs={12} spacing={2}>
                
                {fields.map((iteration) => (
                    <GridFormItem container key={`kt-${iteration}`} xs={12} spacing={2}>
                    <GridFormItem xs={3}>
                        <CFDateField
                            name={`Call Attempt ${iteration}`}
                            value={(remapValue[`Call Attempt ${iteration}`] || null) as Date}
                            onChange={
                                (label: string, newValue: CustomValue | null) => {
                                    const rep = remapValue[`Call Rep Name ${iteration}`];
                                    if(newValue && (!rep || rep == '')) {
                                        onCustomFieldsChange({...remapValue,
                                            [label]: newValue || undefined,
                                            [`Call Rep Name ${iteration}`]:auth?.user.name || ''
                                        });
                                    } else {
                                        callOnChange(label, newValue);
                                    }
                                }
                            }
                            autoFillOnClick={true}
                        />
                    </GridFormItem>
                    <GridFormItem xs={3}>
                        <CFEnumField
                            name={`Phone Type ${iteration}`}
                            value={remapValue[`Phone Type ${iteration}`] as string || ''}
                            clearItem={remapFields[`Phone Type ${iteration}`]?.clearLabel}
                            options={remapFields[`Phone Type ${iteration}`]?.items || []}
                            onChange={callOnChange}
                        />
                    </GridFormItem>
                    <GridFormItem xs={3}>
                        <CFAutocompleteField
                            name={`Call Rep Name ${iteration}`}
                            value={remapValue[`Call Rep Name ${iteration}`] as string || ''}
                            options={remapFields[`Call Rep Name ${iteration}`]?.items || []}
                            onChange={callOnChange}
                        />
                    </GridFormItem>
                    <GridFormItem xs={3}>
                        <CFEnumField
                            name={`Outcome of Phone Attempt ${iteration}`}
                            value={remapValue[`Outcome of Phone Attempt ${iteration}`] as string || ''}
                            clearItem={remapFields[`Outcome of Phone Attempt ${iteration}`]?.clearLabel}
                            options={remapFields[`Outcome of Phone Attempt ${iteration}`]?.items || []}
                            onChange={callOnChange}
                        />
                    </GridFormItem>
                    </GridFormItem>
            ))}
            </GridFormItem>
            //{
                //<GridFormItem xs={12}>
                    //<Box sx={{ height: '2em' }} />
                    //<CustomFields value={customFields || {}} fields={fieldDataSwipe} itemXs={3} onChange={onCustomFieldsChange} />
                //</GridFormItem>
            //}
            || <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>)
    }, [callAttemptFields, customFields, onCustomFieldsChange, 
        client?.repNames, auth?.user.name, client?.layout?.options.numCallAttempts]);


    const customEmailRendList = React.useMemo<JSX.Element>(() => {
        const fieldDataSwipe = emailAttemptFields.map((f) => {
            if(f.type == 'autocomplete') {
                f.items = client?.repNames || [];
            }
            return f;
        });

        const remapValue:{ [k: string]: CustomValue } = customFields || {};
        const remapFields = Object.fromEntries((fieldDataSwipe || {}).map(i => [i.label, i]));
        function callOnChange(label: string, newValue: CustomValue | null) {
            onCustomFieldsChange && onCustomFieldsChange({...remapValue, [label]: newValue || undefined });
        }

        const dateOfName = 'Date of Email Attempt';
        const repName = 'Email Rep Name';
        const outcomeName = 'Outcome of Email Attempt';
        
        //return (emailAttemptFields.length > 0 && <GridFormItem xs={12}><CustomFields value={customFields || {}} fields={fieldDataSwipe} itemXs={4} onChange={onCustomFieldsChange} /></GridFormItem>|| <></>);

        return (emailAttemptFields.length > 0 &&
            <GridFormItem container xs={12} spacing={2}>
                <GridFormItem xs={4}>
                    <CFDateField
                        name={dateOfName}
                        value={(remapValue[dateOfName] || null) as Date}
                        onChange={
                            (label: string, newValue: CustomValue | null) => {
                                const rep = remapValue[repName];
                                if(newValue && (!rep || rep == '')) {
                                    onCustomFieldsChange({...remapValue,
                                        [label]: newValue || undefined,
                                        [repName]:auth?.user.name || ''
                                    });
                                } else {
                                    callOnChange(label, newValue);
                                }
                            }
                        }
                        autoFillOnClick={true}
                    />
                </GridFormItem>
                <GridFormItem xs={4}>
                    <CFAutocompleteField
                        name={repName}
                        value={remapValue[repName] as string || ''}
                        options={remapFields[repName]?.items || []}
                        onChange={callOnChange}
                    />
                </GridFormItem>
                <GridFormItem xs={4}>
                    <CFEnumField
                        name={outcomeName}
                        value={remapValue[outcomeName] as string || ''}
                        clearItem={remapFields[outcomeName]?.clearLabel}
                        options={remapFields[outcomeName]?.items || []}
                        onChange={callOnChange}
                    />
                </GridFormItem>
            </GridFormItem>
        || <></>);

    }, [emailAttemptFields, customFields, onCustomFieldsChange, client?.repNames, auth?.user.name]);

    const customSMSRendList = React.useMemo<JSX.Element>(() => {
        const fieldDataSwipe = smsAttemptFields.map((f) => {
            if(f.type == 'autocomplete') {
                f.items = client?.repNames || [];
            }
            return f;
        });

        const remapValue:{ [k: string]: CustomValue } = customFields || {};
        const remapFields = Object.fromEntries((fieldDataSwipe || {}).map(i => [i.label, i]));
        function callOnChange(label: string, newValue: CustomValue | null) {
            onCustomFieldsChange && onCustomFieldsChange({...remapValue, [label]: newValue || undefined });
        }

        const dateOfName = 'Date of Text Attempt';
        const repName = 'Text Rep Name';
        const outcomeName = 'Outcome of Text Attempt';
        
        //</GridFormItem>return (smsAttemptFields.length > 0 && <GridFormItem xs={12}><CustomFields value={customFields || {}} fields={fieldDataSwipe} itemXs={4} onChange={onCustomFieldsChange} /></GridFormItem>|| <></>);

        return (smsAttemptFields.length > 0 &&
                <GridFormItem container xs={12} spacing={2}>
                    <GridFormItem xs={4}>
                        <CFDateField
                            name={dateOfName}
                            value={(remapValue[dateOfName] || null) as Date}
                            onChange={
                                (label: string, newValue: CustomValue | null) => {
                                    const rep = remapValue[repName];
                                    if(newValue && (!rep || rep == '')) {
                                        onCustomFieldsChange({...remapValue,
                                            [label]: newValue || undefined,
                                            [repName]:auth?.user.name || ''
                                        });
                                    } else {
                                        callOnChange(label, newValue);
                                    }
                                }
                            }
                            autoFillOnClick={true}
                        />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <CFAutocompleteField
                            name={repName}
                            value={remapValue[repName] as string || ''}
                            options={remapFields[repName]?.items || []}
                            onChange={callOnChange}
                        />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <CFEnumField
                            name={outcomeName}
                            value={remapValue[outcomeName] as string || ''}
                            clearItem={remapFields[outcomeName]?.clearLabel}
                            options={remapFields[outcomeName]?.items || []}
                            onChange={callOnChange}
                        />
                    </GridFormItem>
                </GridFormItem>
            || <></>);

    }, [smsAttemptFields, customFields, onCustomFieldsChange, client?.repNames, auth?.user.name]);

    const customMailRendList = React.useMemo<JSX.Element>(() => {
        const fieldDataSwipe = mailAttemptFields.map((f) => {
            if(f.type == 'autocomplete') {
                f.items = client?.repNames || [];
            }
            return f;
        });

        const remapValue:{ [k: string]: CustomValue } = customFields || {};
        const remapFields = Object.fromEntries((fieldDataSwipe || {}).map(i => [i.label, i]));
        function callOnChange(label: string, newValue: CustomValue | null) {
            onCustomFieldsChange && onCustomFieldsChange({...remapValue, [label]: newValue || undefined });
        }

        const dateOfName = 'Date of Mail Attempt';
        const repName = 'Mail Rep Name';
        const outcomeName = 'Outcome of Mail Attempt';

        //return (mailAttemptFields.length > 0 && <GridFormItem xs={12}><CustomFields value={customFields || {}} fields={fieldDataSwipe} itemXs={4} onChange={onCustomFieldsChange} /></GridFormItem> || <></>);

        return (mailAttemptFields.length > 0 &&
            <GridFormItem container xs={12} spacing={2}>
                <GridFormItem xs={4}>
                    <CFDateField
                        name={dateOfName}
                        value={(remapValue[dateOfName] || null) as Date}
                        onChange={
                            (label: string, newValue: CustomValue | null) => {
                                const rep = remapValue[repName];
                                if(newValue && (!rep || rep == '')) {
                                    onCustomFieldsChange({...remapValue,
                                        [label]: newValue || undefined,
                                        [repName]:auth?.user.name || ''
                                    });
                                } else {
                                    callOnChange(label, newValue);
                                }
                            }
                        }
                        autoFillOnClick={true}
                    />
                </GridFormItem>
                <GridFormItem xs={4}>
                    <CFAutocompleteField
                        name={repName}
                        value={remapValue[repName] as string || ''}
                        options={remapFields[repName]?.items || []}
                        onChange={callOnChange}
                    />
                </GridFormItem>
                <GridFormItem xs={4}>
                    <CFEnumField
                        name={outcomeName}
                        value={remapValue[outcomeName] as string || ''}
                        clearItem={remapFields[outcomeName]?.clearLabel}
                        options={remapFields[outcomeName]?.items || []}
                        onChange={callOnChange}
                    />
                </GridFormItem>
            </GridFormItem>
        || <></>);
    }, [mailAttemptFields, customFields, onCustomFieldsChange, client?.repNames, auth?.user.name]);


    const customGenFieldsRendList = React.useMemo<JSX.Element>(() => {
        return (otherCustomFields.length > 0 &&
            <GridFormItem xs={12}>
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Custom Fields
                </Typography>
                <Box sx={{ height: '2em', display: 'block' }} />
                <CustomFields value={customFields || {}} fields={otherCustomFields} onChange={onCustomFieldsChange} />
            </GridFormItem>
            || <></>);
    }, [otherCustomFields, customFields, onCustomFieldsChange]);


    const readOnlyFieldsRendList = React.useMemo<JSX.Element>(() => {

        const keysWithoutFields = Object.keys(customFields).filter((k) => !allFieldKeys.includes(k)).map((key, i) => (
            <GridFormItem key={`${i}`} xs={4}>
                <TextField label={key} name={key} value={customFields[key] as string} variant='standard'/>                             
            </GridFormItem>
        )) || [];
        return (keysWithoutFields.length > 0 &&
            (<GridFormItem xs={12}>
            <GridForm>
                {
                    keysWithoutFields
                }
            </GridForm>
            </GridFormItem>)
            || <></>);
    }, [customFields, allFieldKeys]);

    const schedulablePatientTimeComp = React.useMemo<JSX.Element>(() => {
        const openBookDialog = (slot:SchedulableSlot|null|undefined) => {
            setScheduleTime(slot||null);
        };

        return (
            <GridFormItem xs={12}>
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Proposed Schedule
                </Typography>
                As Of: { (schedulableSlots
                    ? DateTime.fromISO(schedulableSlots?.info?.lastEditedDate || '').toLocaleString(DateTime.DATE_SHORT) 
                    : DateTime.now().toLocaleString(DateTime.DATE_SHORT)) || '' }
                <SchedulableTimeList items={schedulableSlots
                    && DateTime.fromISO(schedulableSlots?.time || '').toUnixInteger() > DateTime.now().toUnixInteger()
                    ?[schedulableSlots]:undefined} onBook={openBookDialog}/>
            </GridFormItem>
        );
    },[schedulableSlots]);


    const { apt, apts, onAptChange, onAptInputChange, aptLabel, compareApts } = useAptSearch(
        client, patient, scheduled1 || null, appointment
    )

    const lastEditFooter = React.useMemo(() => {
        const udt = visit?.info?.lastEditedDate ? visit?.info?.lastEditedDate : visit?.updated;
        const ntm = udt ? `Updated: ${(udt.includes('.') ? udt.split('.')[0] : udt)} by ${(visit?.info?.lastEditedBy || 'unknown')}` : '';
        return { text:ntm, tooltip:visit?.info?.lastEditReason };
    }, [visit?.updated, visit?.info]);

    const [callAttempts, emailAttempts, smsAttempts, mailAttempts, contactAttempts] = React.useMemo(() => {
        const callA = callAttemptFields.filter((f) => { return (f?.type == 'date') && customFields[f.label]; } )?.length || 0;
        const emailA = (emailAttemptFields.filter((f) => { return (f?.type == 'date') && customFields[f.label]; } )?.length || 0);
        const smsA = (smsAttemptFields.filter((f) => { return (f?.type == 'date') && customFields[f.label]; } )?.length || 0);
        const mailA = (mailAttemptFields.filter((f) => { return (f?.type == 'date') && customFields[f.label]; } )?.length || 0)
        return [callA, emailA, smsA, mailA, (callA + emailA + smsA + mailA)];
    }, [customFields, callAttemptFields, emailAttemptFields, smsAttemptFields, mailAttemptFields]);

    const onSubmitClick = useCallback(async () => {
        const errors = {
            status: !status,
        };
        setErrors(errors);
        if (Object.values(errors).reduce((acc, val) => acc || val)) {
            return false;
        }

        const lastChanges = Object.entries(trackChanges).filter(([, value]) => value).map(([k]) => k);
        const info:DocumentInfo = authDocumentInfo(auth?.user, lastChanges.join(','));
        const lastContact = findLastContactDate(customFields);

        const newVisit = {
            status: status as VisitStatus,
            language: language || undefined,
            scheduled1: scheduled1?.toISO({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false }) || undefined,
            scheduled2: scheduled2?.toISO({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false }) || undefined,
            scheduled3: scheduled3?.toISO({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false }) || undefined,
            scheduled4: scheduled4?.toISO({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false }) || undefined,
            scheduledReason1: scheduledReason1 || undefined,
            scheduledReason2: scheduledReason2 || undefined,
            scheduledReason3: scheduledReason3 || undefined,
            scheduledReason4: scheduledReason4 || undefined,
            comments: comments || undefined,
            sentToPCP: (sentToPCP != null && sentToPCP != undefined && sentToPCP != false) ? true : false,
            clientNotes: clientNotes || undefined,
            clientDataEntry: clientDataEntry || undefined,
            appointmentId: appointment?.id || undefined,
            customFields: formatDates(customFields),
            numCalls: callAttempts,
            numEmails: emailAttempts,
            numSMS: smsAttempts,
            numMails: mailAttempts,
            numContacts: contactAttempts,
            numReschedules : numReschedules,
            refusalReason: refusalReason || undefined,
            phoneDate: phoneDate?.toISODate() || undefined,
            emailDate: emailDate?.toISODate() || undefined,
            utrDate: utrDate?.toISODate() || undefined,
            scheduledDate: getNewestDate([scheduled1, scheduled2, scheduled3, scheduled4])?.toISODate() || undefined,
            phoneOutcome: phoneOutcome || undefined,
            emailOutcome: emailOutcome || undefined,
            appointmentOutcome: appointmentOutcome || undefined,
            notes: notes || undefined,
            memberInfoUpdate: memberInfoUpdate || undefined,
            info: info,
            lastContactDate: lastContact?.toISO({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false }) || 'unknown'
        };
        analytics.track(visit ? 'visit_update' : 'visit_create', {
            clientId: clientId,
            patientId: patientId,
            changes: lastChanges
        }, true);
        if (visit) {
            await API.updateVisit({ ...visit, ...newVisit });
        }
        else {
            await API.createVisit({ clientId, patientId, refId, ...newVisit });
        }
        return true;
    }, [status, language, scheduled1, scheduled2, scheduled3, scheduled4, comments, sentToPCP,
        scheduledReason1, scheduledReason2, scheduledReason3, scheduledReason4, refusalReason, trackChanges,
        clientNotes, clientDataEntry, appointment?.id, customFields, numReschedules, phoneDate, emailDate, utrDate,
        phoneOutcome, emailOutcome, appointmentOutcome, notes, memberInfoUpdate, auth, visit, clientId, patientId, refId,
        contactAttempts, emailAttempts, smsAttempts, mailAttempts, callAttempts]
    );

    return (
        <EntityEditor {...props} readOnly={readOnly} title={(readOnly ? 'View' : 'Edit') + ' Visit'} 
            footer={lastEditFooter} doSave={onSubmitClick} backdropClose={false} >

            <GridFormItem xs={12}>
            <Tabs value={displayTabValue} 
                onChange={(event: React.SyntheticEvent, newValue: number) => { setDisplayTabValue(newValue)} }
                TabIndicatorProps={{ style: { backgroundColor: 'blue' } }}>
                <Tab label="Visit" />
                <Tab label="Patient" />
                <Tab label="Conditions" />
                <Tab label="Measures" />
            </Tabs>
            </GridFormItem>


            {
            displayTabValue == 0 ? (
            <GridFormItem xs={12}>
            <GridForm>

            <EnumField name='status' label='Status' value={status}
                error={errors.status} onChange={onEnumFieldChange} options={VisitStatuses} required readOnly={readOnly} />
            <GridFormItem xs={6}>
                <Autocomplete
                    options={apts}
                    value={apt}
                    onChange={onAptChange}
                    onInputChange={onAptInputChange}
                    autoHighlight
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            name='appointment'
                            label='Patient name, apt date or id'
                            variant='standard'
                            fullWidth
                        />
                    }
                    getOptionLabel={aptLabel}
                    isOptionEqualToValue={compareApts}
                    readOnly={readOnly}
                />

            </GridFormItem>

            <GridFormItem xs={6}>
                <EnumField name='refusalReason' label='Refusal or UTR Reason' value={refusalReason || ''}
                    onChange={onEnumFieldChange} readOnly={readOnly} options={RefusalReasons} clearItem={'Clear Field'}/>
            </GridFormItem>
            <GridFormItem xs={6}>
                <DateField name='utrDate' value={utrDate} label='Date Referred for Unable to Contact'
                    onChange={onDateFieldChange} readOnly={readOnly}/>
            </GridFormItem>
            <EnumField name='language' label='Language' value={language || UnknownLanguage}
                onChange={onEnumFieldChange} readOnly={readOnly} options={Languages} />
            <EnumField name='memberInfoUpdate' value={memberInfoUpdate || ''} label='Updated information for member'
                onChange={onEnumFieldChange} options={MemberInfoUpdates} readOnly={readOnly} clearItem={'Clear Field'}/>

            <TextField name='numReschedules' value={numReschedules} label='Number of Reschedules to Date'
                onChange={onTextFieldChange} variant='standard' type='number' disabled={readOnly} fullWidth />
            <TextField name='comments' label='ZiphyCare Comments' value={comments || ''}
                onChange={onTextFieldChange} variant='standard' multiline fullWidth />

            <GridFormItem xs={6} >
                <TextField name='clientDataEntry' value={clientDataEntry || ''} label='Client Data Entry'
                    onChange={onTextFieldChange} variant='standard' disabled={readOnly || disabledFields.includes('clientDataEntry')} fullWidth />
            </GridFormItem>
            <GridFormItem xs={6} >
                <TextField name='clientNotes' value={clientNotes || ''} label='Client Notes'
                    onChange={onTextFieldChange} variant='standard' disabled={readOnly || disabledFields.includes('clientNotes')} fullWidth />
            </GridFormItem>

            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>

            <GridFormItem xs={12} >
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Outreach History
                </Typography>
            </GridFormItem>

            <GridFormItem xs={6}>
                <Typography>{`Contact Attempts: ${contactAttempts}`}</Typography>
            </GridFormItem>
            <GridFormItem xs={6}>
                <Typography>{`Call Attempts: ${callAttempts}`}</Typography>
            </GridFormItem>

            {
                /* Call Attempts */
                customCallRendList
            }
            <GridFormItem xs={4}><Box/></GridFormItem>
            <GridFormItem xs={12}><Box/></GridFormItem>
            {
                /* Email Render List */
                customEmailRendList
            }
            {
                /* SMS Render List */
                customSMSRendList
            }
            {
                customMailRendList
            }
            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>

            {
                /* Proposed Schedule */
                schedulablePatientTimeComp
            }
            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>

            {/* Appointment History*/}
            <GridFormItem xs={12}>
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Appointment History
                </Typography>
            </GridFormItem>

            <GridFormItem xs={6}>
                <DateTimeField name='scheduled1' value={scheduled1} label='Scheduled 1st'
                    onChange={onDateFieldChange} readOnly={readOnly}
                    disabled={disabledFields.includes('scheduled1')} />
            </GridFormItem>
            <GridFormItem xs={6}>
                <EnumField name='scheduledReason1' value={scheduledReason1 || ''} label='Reason Appointment Did Not Happen'
                    onChange={onEnumFieldChange} options={ScheduledFailedReasons} readOnly={readOnly} clearItem={'Clear Field'}
                    disabled={disabledFields.includes('scheduledReason1')} />
            </GridFormItem>

            <GridFormItem xs={6}>
                <DateTimeField name='scheduled2' value={scheduled2} label='Scheduled 2nd'
                    onChange={onDateFieldChange} readOnly={readOnly}
                    disabled={disabledFields.includes('scheduled2')} />
            </GridFormItem>
            <GridFormItem xs={6}>
                <EnumField name='scheduledReason2' value={scheduledReason2 || ''} label='Reason Appointment Did Not Happen'
                    onChange={onEnumFieldChange} options={ScheduledFailedReasons} readOnly={readOnly} clearItem={'Clear Field'}
                    disabled={disabledFields.includes('scheduledReason2')} />
            </GridFormItem>

            <GridFormItem xs={6}>
                <DateTimeField name='scheduled3' value={scheduled3} label='Scheduled 3rd'
                    onChange={onDateFieldChange} readOnly={readOnly}
                    disabled={disabledFields.includes('scheduled3')} />
            </GridFormItem>
            <GridFormItem xs={6}>
                <EnumField name='scheduledReason3' value={scheduledReason3 || ''} label='Reason Appointment Did Not Happen'
                    onChange={onEnumFieldChange} options={ScheduledFailedReasons} readOnly={readOnly} clearItem={'Clear Field'}
                    disabled={disabledFields.includes('scheduledReason3')} />
            </GridFormItem>

            <GridFormItem xs={6}>
                <DateTimeField name='scheduled4' value={scheduled4} label='Scheduled 4th'
                    onChange={onDateFieldChange} readOnly={readOnly}
                    disabled={disabledFields.includes('scheduled4')} />
            </GridFormItem>
            <GridFormItem xs={6}>
                <EnumField name='scheduledReason4' value={scheduledReason4 || ''} label='Reason Appointment Did Not Happen'
                    onChange={onEnumFieldChange} options={ScheduledFailedReasons} readOnly={readOnly} clearItem={'Clear Field'}
                    disabled={disabledFields.includes('scheduledReason4')} />
            </GridFormItem>

            <GridFormItem xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControlLabel sx={{ '& span.MuiButtonBase-root': { paddingBottom: 0, paddingTop: 0 } }}
                    control={
                        <Checkbox
                            name='sentToPCP'
                            readOnly={readOnly}
                            checked={((sentToPCP != null && sentToPCP != undefined) ? !!sentToPCP : false)}
                            onChange={onTextFieldChange} />
                    }
                    disabled={disabledFields.includes('sentToPCP')}
                    label="Sent to PCP" />
            </GridFormItem>
            <GridFormItem xs={6}><Box sx={{ height: '2em' }} /></GridFormItem>
            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>

            <GridFormItem xs={12}>
                <TextField name='notes' value={notes} label='Notes'
                    onChange={onTextFieldChange} variant='standard'
                    multiline fullWidth disabled={readOnly} />
            </GridFormItem>
            <Box sx={{ height: '2em', display: 'block' }} />
            {
                /* Other custom fields */
                customGenFieldsRendList
            }
            {
                readOnlyFieldsRendList
            }
            <input type='hidden' name='appointmentId' value={apt && apt.id || undefined} />

            </GridForm>
            </GridFormItem>
            ):
            displayTabValue == 1 && client != null ? (
                <GridFormItem xs={12}>
                    <PatientEditor open={true} patient={patient} client={client} plans={[]} editorMode={PatientEditorMode.Grid} 
                        sections={[PatientSection.Demographics, PatientSection.Contacts]} readOnly={true} />
                </GridFormItem>
            ) :
            displayTabValue == 2 && patient != null ? (
                (<GridFormItem xs={12}>
                    <ConditionInfoEditor readonly={true} patient={patient} state={patientConditionState}/>
                </GridFormItem>)
            ) :
            displayTabValue == 3 && patient != null ? (
                (<GridFormItem xs={12}>
                    <PatientMeasureEditor readonly={true} state={patientMeasureState}/>
                </GridFormItem>)
            ) : <></>
            }

        </EntityEditor >
    )
}

export default VisitEditor;